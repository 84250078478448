  .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 3.5rem;
    padding: 2.5rem 4rem;
  }

  .imgContainer {
    border-radius: 8px;
    transition: transform 0.2s;
    text-align: center;
  }

  .imageStyle {
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    max-height: 32rem;
  }

  h4 {
    padding: 0.5rem;
  }

  .routeContainer {
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem 3rem;
  }

  .directionContainer {
    display: flex;
    gap: 5rem;
    padding: 0.5rem 0rem;

    label {
      padding-left: 0.2rem;
    }
  }

  .labels {
    display: grid;
    gap: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
  }

  .radius {
    border-radius: 16px;
  }

  .table-container {
    min-width: 600px;
    border-radius: 16px;
    background: #f4f9ff;
    border: 1px solid;
    overflow: hidden;

    table {
      width: 100%;
      font-family: Arial, sans-serif;
      text-align: left;
    }

    th, td {
      padding: 12px;
      border: 0;
      border-bottom: 1px solid;
    }

    th {
      font-size: 14px;
      font-weight: bold;
    }

    tbody tr {
      position: sticky;
      background-color: #fff;
    }

    table tbody {
      display: block;
      max-height: 400px;
      overflow-y: scroll;
    }

    table thead,
    table tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .action-button {
    background-color: #216FFF;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 12px;
  }

  .action-button:hover {
    background-color: #0056b3;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }