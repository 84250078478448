.routeMapContainerGrid {
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 3fr;
    align-items: start;
}

.routeMapContainer {
    display: grid;
    gap: 1em;
    /* justify-content: center;
    align-items: center;
    align-items: flex-start;
    flex-wrap: wrap; */
    align-items: start;
}

.mapContainer {
    display: grid;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.source {
    height: 2.5rem;
    border-radius: 13px;
}

.stopIcon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.iconStyle {
    height: 1.3rem;
}

.upDownIcon {
    height: 3.5rem;
    margin-bottom: -3rem;
}