.addressContainer {
  display: flex;
  justify-content: center;
  gap: 3em;
  flex-wrap: wrap;
  width: auto;
}

.topSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}

.searchBox {
  width: 350px;
  height: 40px;
  border-radius: 8px;
  padding: 10px;
  font-size: 1rem;
}

.setLocationButton {
  height: 2rem;
  width: 15rem;
  border-radius: 8px;
  margin: 1em;
  background-color: #002664;
  color: #FFFFFF;
  border: 0;
}

.buttonSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9rem;
}

.cancelBtn {
  border-radius: 8px;
  background-color: #DB0000;
  color: #FFFFFF;
  width: 5em;
  border: 0;
  padding: 0.1rem;
}

.saveBtn {
  border-radius: 8px;
  background-color: #216FFF;
  color: #FFFFFF;
  width: 5em;
  border: 0;
  padding: 0.1rem;
}

.saveLocationBtn {
  border-radius: 8px;
  background-color: #216FFF;
  color: #FFFFFF;
  width: 8em;
  border: 0;
  padding: 0.1rem;
}

.location{
  display: grid;
  padding: 1rem 0.5rem;
  gap: 0.2rem;
  font-size: 0.875rem;
  font-weight: 600;
  max-width: 350px;
}